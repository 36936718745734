<template>
  <b-row class="px-1">
    <b-col cols="6">
      <div class="d-flex justify-content-between">
        <span class="k-font-weight-bold font-medium-1">TASK</span>

        <!-- <b-button
          variant="transparent"
          size="sm"
          v-b-tooltip.hover.lefttop="'Court Info Tasks'"
          @click="openDsCourtInfoTasks"
        >
          <ScaleIcon />
        </b-button> -->
      </div>
      <b-table :items="taskItems" :fields="fields">
        <template #cell(actions)="data">
          <b-row>
            <b-col>
              <feather-icon
                v-if="!isResponsibleAccount"
                icon="CheckIcon"
                class="text-success cursor-pointer"
                size="20"
                @click="checkTask(data.item.id)"
              />
            </b-col>
            <b-col>
              <feather-icon
                v-if="!isResponsibleAccount"
                icon="Edit3Icon"
                class="text-warning cursor-pointer"
                size="20"
                @click="editTask(data.item)"
              />
            </b-col>
            <b-col>
              <feather-icon
                icon="EyeIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="showTask(data.item)"
              />
            </b-col>
            <b-col>
              <feather-icon
                v-if="!isResponsibleAccount"
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="20"
                @click="deleteTask(data.item.id)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-row class="justify-content-between px-1 mt-1">
        <b-button variant="primary" @click="openiListCompletedTasksModal">
          <feather-icon icon="EyeIcon" /> View completed tasks
        </b-button>
        <b-button :disabled="isResponsibleAccount" variant="primary" @click="openModalAddTask">
          <feather-icon icon="PlusIcon" /> Add task
        </b-button>
      </b-row>
    </b-col>
    <br />
    <b-col cols="6">
      <b-row class="justify-content-between pl-4 pr-1">
        <b-form-group label="Notes">
          <b-form-select
            v-model="noteSelected"
            @change="changeYear"
            :options="years"
          />
        </b-form-group>
        <b-button :disabled="isResponsibleAccount" class="my-2" variant="primary" @click="openAddNotesModal">
          <feather-icon icon="PlusIcon" /> Add Note
        </b-button>
      </b-row>
      <b-row class="w-100 m-1">
        <b-col cols="2" v-for="(month, i) in months" :key="i">
          <b-card
            class="border-light rounded-lg"
            border-variant="info"
            :header="month"
            header-class=" py-1"
            header-bg-variant="info"
            header-text-variant="white"
            body-class="py-1"
            align="center"
          >
            <b-card-text>
              <h4
                class="cursor-pointer text-info m-0 p-0"
                @click="openModalAllNotesByMonth(i)"
              >
                {{ noteItems[i] }}
              </h4>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="pl-3 mt-1 justify-content-start">
        <b-button :disabled="isResponsibleAccount" variant="warning" @click="openModalNotesAll">
          <feather-icon icon="Edit2Icon" /> First Note
        </b-button>
      </b-row>
    </b-col>
    <add-task-modal
      v-if="isActiveModalAddTask"
      :task-data="taskToSend"
      :to-edit="toEdit"
      :show-mood="showMood"
      :aid="clientData.advisor.id"
      :aname="clientData.advisor.name"
      :sid="clientData.supervisor.id"
      :sname="clientData.supervisor.name"
      :name-client="client"
      @close="closeModalAddTask"
      @reload="reloadTasks"
    />
    <list-completed-tasks-modal
      v-if="isActiveListCompletedTasksModal"
      :name-client="client"
      @close="closeListCompletedTasksModal"
    />
    <add-notes-modal
      v-if="isActiveAddNotesModal"
      :name-client="client"
      :editing="false"
      @close="closeAddNotesModal"
      @reload="reloadNotes"
    />
    <modal-notes-paragon
      v-if="isActiveModalNotesAll"
      :note-info="noteInfo"
      @hide="closeModalNotesAll"
    />
    <list-all-notes-modal
      v-if="isActiveModalAllNotes"
      :month="notesData.month"
      :year="notesData.year"
      :nameClient="client"
      @close="closeModalAllNotesByMonth"
    />
    <ModalNotesFirst
      v-if="isActiveModalBusinessNotes"
      :note-info="noteInfo"
      @hide="closeModalBusinessNotesAll"
    />
    <!-- <DSCourtInfoTasks
      v-if="showDsCourtInfoTasks"
      :id="clientData.accountId"
      @close="showDsCourtInfoTasks = false"
    /> -->
  </b-row>
</template>
<script>
//services
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
//vuex
import { mapActions, mapGetters } from "vuex";
//modals
import ModalAllNotes from "../modals/ModalAllNotes.vue";
import ListCompletedTasksModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListCompletedTasksModal.vue";
import AddNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddNotesModal.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import AddTaskModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddTaskModal.vue";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
// import DSCourtInfoTasks from "@/views/debt-solution/views/court-info/components/modals/DSCourtInfoTasks.vue";
// import ScaleIcon from "@/views/ui/icons/ScaleIcon.vue";

export default {
  components: {
    AddNotesModal,
    AddTaskModal,
    ListCompletedTasksModal,
    ModalAllNotes,
    ModalNotesAll,
    ListAllNotesModal,
    ModalNotesParagon,
    ModalNotesFirst,
    // DSCourtInfoTasks,
    // ScaleIcon,
  },
  data() {
    return {
      isActiveModalAllNotes: false,
      isActiveModalAddTask: false,
      isActiveAddTaskModal: false,
      isActiveListCompletedTasksModal: false,
      isActiveAddNotesModal: false,
      isActiveModalNotesAll: false,
      isActiveListAllNotesModal: false,
      isActiveModalBusinessNotes: false,
      noteSelected: new Date().getFullYear(),
      notesData: { month: "", year: "" },
      toEdit: false,
      taskToSend: {},
      showMood: false,
      fields: [
        { key: "subject", label: "Subject" },
        { key: "date", label: "Date / Hour" },
        { key: "actions", label: "Actions", thClass: "text-center" },
      ],
      // showDsCourtInfoTasks: false,
      // noteInfo:{},
    };
  },
  methods: {
    // openDsCourtInfoTasks() {
    //   this.showDsCourtInfoTasks = true;
    // },

    openAddTaskModal() {
      this.isActiveAddTaskModal = true;
    },
    closeAddTaskModal() {
      this.isActiveAddTaskModal = false;
    },
    openListAllNotesModal() {
      this.isActiveListAllNotesModal = true;
    },
    closeListAllNotesModal() {
      this.isActiveListAllNotesModal = false;
    },
    openModalNotesAll() {
      if (this.clientData.program == "Business" && this.noteInfo.module == 3) {
        this.isActiveModalBusinessNotes = true;
        return;
      }
      this.isActiveModalNotesAll = true;
    },
    closeModalBusinessNotesAll() {
      this.isActiveModalBusinessNotes = false;
    },
    closeModalNotesAll() {
      this.isActiveModalNotesAll = false;
    },
    openAddNotesModal() {
      this.isActiveAddNotesModal = true;
    },
    closeAddNotesModal() {
      this.isActiveAddNotesModal = false;
    },
    openiListCompletedTasksModal() {
      this.isActiveListCompletedTasksModal = true;
    },
    closeListCompletedTasksModal() {
      this.isActiveListCompletedTasksModal = false;
    },
    openModalAddTask() {
      this.taskToSend = {};
      this.toEdit = true;
      this.isActiveModalAddTask = true;
    },
    closeModalAddTask() {
      this.taskToSend = {};
      this.showMood = false;
      this.isActiveModalAddTask = false;
    },
    openModalAllNotesByMonth(index) {
      this.notesData.year = `${this.noteSelected}`;
      this.notesData.month = index + 1;
      this.isActiveModalAllNotes = true;
    },
    closeModalAllNotesByMonth() {
      this.isActiveModalAllNotes = false;
    },
    changeYear() {
      this.notesData.year = this.noteSelected;
      const params = {
        id: this.accountId,
        year: this.noteSelected,
        iduser: this.currentUser.user_id,
      };
      this.getNotesData(params);
    },
    showTask(item) {
      let data = item;
      data.date1 = item.date.substr(0, 10);
      data.hour = item.date.substr(11);
      this.isActiveModalAddTask = true;
      this.showMood = true;
      this.taskToSend = data;
    },
    async checkTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.checkTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.reloadTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    editTask(item) {
      let data = item;
      data.date1 = item.date.substr(0, 10);
      data.hour = item.date.substr(11);
      this.taskToSend = data;
      this.toEdit = true;
      this.isActiveModalAddTask = true;
    },
    async deleteTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.reloadTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async reloadTasks() {
      await this.getTaskData(this.taskParams);
    },
    reloadNotes() {
      const params = {
        id: this.accountId,
        year: this.noteSelected,
        iduser: this.currentUser.user_id,
      };
      this.getNotesData(params);
    },
    ...mapActions({
      getNotesData: "ParagonClientDashboard/getNotesData",
      getTaskData: "ParagonClientDashboard/getTaskData",
    }),
  },
  computed: {
    taskParams() {
      return {
        id: this.accountId,
        user_id: this.currentUser.user_id,
      };
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      accountId: "ParagonClientDashboard/getAccountId",
      months: "ParagonClientDashboard/getMonths",
      years: "ParagonClientDashboard/getYears",
      taskItems: "ParagonClientDashboard/getTaskData",
      taskPaginate: "ParagonClientDashboard/getTaskPaginate",
      noteItems: "ParagonClientDashboard/getNotesData",
      client: "ParagonClientDashboard/getClient",
      clientData: "ParagonClientDashboard/getPersonalInputs",
      noteInfo: "ParagonClientDashboard/getNoteInfo",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>
