<template>
  <b-modal v-model="isActive" @hidden="closeMe" size="lg" hide-footer>
      <client-year-month-header :client="client" :month="month" :year="year" />
  </b-modal>
</template>
<script>
//components
import ClientYearMonthHeader from '@/views/paragon/views/clients/dashboard/views/components/utils/ClientYearMonthHeader.vue'
//vuex
import {mapGetters} from 'vuex'
export default {
  created(){
    this.isActive = this.active
  },
  props:{active:{type:Boolean},month:{type:[String,Number]},year:{type:[String,Number]}},
  components:{ClientYearMonthHeader},
  data(){return{
    isActive: false,
  }},
  methods:{
    closeMe(){
        this.isActive = false
        this.$emit('close')
    }
  },
  computed:{
    ...mapGetters({
        client:'ParagonClientDashboard/getClient',
    }),
  },
}
</script>