<template>
  <b-row>
    <b-col cols="6">
      <h5>Client</h5>
      <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
        {{ client }}
      </p>
    </b-col>
    <b-col cols="3">
      <h5>Year</h5>
      <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
        {{ year }}
      </p>
    </b-col>
    <b-col cols="3">
      <h5>Month</h5>
      <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
        {{ month }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    month: {
      type: [String,Number],
      required: true,
    },
    year: {
      type: [String,Number],
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
